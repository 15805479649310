// LOGIN OR REGISTER
export const LOGIN_SCREEN = "LOGIN";
export const REGISTER_SCREEN = "REGISTER";
export const OTP_SCREEN = "OTP";
export const LOGIN = "Login";
export const REGISTER = "REGISTER";
export const REGISTER_NOW = "SIGN UP";
export const WITH_OTP = "Existing User?";
export const VERIFY_MOBILE_NUMBER = "VERIFY MOBILE NUMBER";
export const NICK_NAME = "NICK NAME";
export const OPTIONAL = "OPTIONAL";
export const CONTACT_US = "Contact Us";
export const PHONE_NUMBER = "Phone Number";
export const LOGIN_WITH_OTP = "GET OTP & CONTINUE";
export const YOUR_NUMBER_WILL_NOT_BE_SHARED = "Your number will not be shared";
export const ENTER_YOUR_PHONE_NUMBER = "Enter your phone number";
export const LOGIN_SIGNUP = "LOGIN/SIGNUP";
export const TERMS_AND_CONDITIONS_TEXT = "By registering you accept you are 18+ and agree to our ";
export const TERMS_AND_CONDITIONS = "Terms & Conditions";
export const NEW_HERE = "New here?";
export const RESEND_OTP = "Resend";
export const VERIFY_OTP = "VERIFY OTP";
export const CHANGE = "Change";
export const ENTER_OTP = "Enter OTP";
export const WRONG_NUMBER = "Wrong number?";
export const OTP_SENT_TO = "OTP sent to";
export const PLEASE_ENTER_OTP = "Please enter the OTP sent to your mobile number";

// Dashboard
export const ACCOUNT_ = "Account >";
export const ENTRY_FEE = "Entry Fee";
export const JACKPOT_PRIZE = "JACKPOT PRIZE";
export const WIN_FROM = "Win From";
export const MINIMUM_PRIZE = "Minimum Prize";
export const HOME = "Home";
export const WALLET = "Points";
export const ACCOUNT = "Account";
export const NEXT_SPIN_IN = "NEXT SPIN IN";
export const PLAY = "PLAY";

// Wallet
export const CASH_BALANCE = "Cash Balance";
export const ADD_CASH = "ADD CASH";
export const WITHDRAW = "WITHDRAW";
export const HISTORY = "HISTORY";
export const ENTER_AMOUNT = "ENTER AMOUNT";
export const SELECT_PAYMENT_METHOD = "SELECT PAYMENT METHOD";
export const ENTER_AMOUNT_TO_WITHDRAW = "ENTER AMOUNT TO WITHDRAW";
export const AVAILABLE_WITHDRAW_METHOD = "AVAILABLE WITHDRAW METHOD";
export const CARD_NETBANKING_UPI_WALLET = "CARD / NETBANKING / UPI / WALLET";
export const SELECT_A_PHOTO_TO_PROCEED = "Select a Photo ID to Proceed";
export const PAN_CARD = "Pan Card";
export const INSTANT_VERIFICATION = "Instant Verification";
export const AADHAAR = "Aadhaar";
export const NETBANKING = "NETBANKING";
export const UPI = "UPI";
export const BANK_ACCOUNT_ = "BankAccount";
export const UPI_ID_ = "UpiId";
export const TAKES_THIRTY_MINUTES_FOR_VERIFICATION = "Takes upto 30 mins for verification";
export const WITHDRAWALS_TEMPORARILY_UNAVAILABLE = "Withdrawals temporarily \n unavailable";
export const WITHDRAWAL_SUCCESSFUL = "WITHDRAWAL SUCCESSFUL";
export const WITHDRAWAL_ID = "Withdrawal ID";
export const MONEY_SENT_TO_YOUR_BANK_ACCOUNT = "MONEY SENT TO YOUR BANK ACCOUNT";
export const IT_MIGHT_TAKE_UPTO_ONE_HOUR_TO_REFLECT_CHANGES_IN_BANK = "It might take up to 1 hr to reflect in your bank account";
export const UTR_NO = "UTR No";
export const COPY = "Copy";
export const PLEASE_CHECK_YOUR_BANK_STATEMENT =
    "Please check your bank statement for the final status of the transaction and In case the money has not been credited, please contact your back with above mentioned UTR number";
export const TOTAL_WITHDRAW_REQUESTED = "Total withdraw requested";
export const TDS_CHARGED = "TDS charged";
export const AMOUNT_TRANSFERRED = "Amount transferred";
export const WITHDRAWAL_METHOD = "Withdrawal method";
export const CONFIRM_WITHDRAWAL = "CONFIRM WITHDRAWAL";
export const REQUESTED_TRANSFER_AMOUNT = "Requested transfer amount";
export const TAXABLE_AMOUNT = "Taxable amount";
export const NET_TRANSFER_AMOUNT = "Net transfer amount";
export const CONFIRM_AND_WITHDRAW = "CONFIRM & WITHDRAW";
export const CLOSE = "Close";
export const BANK_NAME = "Bank Name";
export const ACCOUNT_HOLDER_NAME = "Account Holder Name";
export const ACCOUNT_NUMBER = "Account Number";
export const CONFIRM_ACCOUNT_NUMBER = "Confirm Account Number";
export const IFSC_CODE = "IFSC Code";
export const TRANSFER_TYPE = "Transfer Type";
export const UPI_ID = "UPI ID";
export const LOADING_BUTTON = "Waiting...";

// Account
export const MY_ACCOUNT = "My Account";
export const CHANGE_PROFILE_PICTURE = "CHANGE PROFILE PICTURE";
export const SHARE_A_FREE_SPIN = "SHARE A FREE SPIN";
export const UPLOAD_IMAGE = "Upload Image";
export const CHOOSE_FROM_GALLERY = "Choose From Gallery";
export const TAKE_A_PHOTO = "Take a Photo";
export const ALL_TRANSACTIONS = "All Transaction";
export const VIEW = "View";
export const COMPLETE_KYC = "Complete Kyc";
export const VERIFY_NOW = "VERIFY NOW";
export const UPDATE = "Update";
export const SET = "SET";
export const KYC_VERIFIED = "KYC Verified";
export const KYC_UNDER_REVIEW = "KYC Under Review";
export const KYC_VERIFICATION_FAILED = "KYC Verification Failed!";
export const VERIFY_AGAIN = "VERIFY AGAIN";
export const VIEW_DOCUMENTS = "VIEW DOCUMENTS";
export const CONTINUE = "Continue";
export const UPLOAD_FRONT = "Upload Front";
export const UPLOAD_BACK = "Upload Back";
export const REUPLOAD_PAN_CARD = "Re-Upload Pan Card";
export const UPLOAD_PAN_CARD = "Upload Pan Card";
export const UPLOAD_FRONT_AND_BACK_OF_AADHAAR = "Upload Front and Back of Aadhar";
export const UPLOAD_FRONT_SIDE_OF_PAN_CARD = "Upload Front Side of Pan Card";
export const UNFORTUNATELY_VERIFICATION_FAILED = "Unfortunately your KYC documents did \n not meet our compliance regulations. \n Kindly re-upload your documents or \n contact customer services.";
export const PAN_AADHAR_VERIFICATION_REQUIRED = "PAN / Aadhaar verification is \n \n required to withdraw Winning Cash.";
export const YOUR_KYC_DOCUMENT_ARE_UNDER_REVIEW = "Your KYC document are currently \n under review to ensure compliance with regulations \n Withdrawals will be available shortly.";

// Slider Block
export const PRIZE = "PRIZE";
export const FREQUENCY = "FREQUENCY";
export const TIMES = "times";

// KYC
export const PAN_NUMBER = "Pan Number";
export const AADHAR_Front = "Aadhar Front";
export const AADHAR_Back = "Aadhar Back";
export const AADHAR_NUMBER = "Aadhar Number";
export const AADHAR_CARD_ = "AadharCard";
export const AADHAR_CARD = "Aadhar Card";
export const PAN_CARD_ = "PanCard";
export const KYC_DOCUMENT_SUBMITTED = "KYC DOCUMENTS \n SUBMITTED!";
export const BACK_TO_WALLET = "BACK TO WALLET";
export const TXN = "Txn";
export const TYPE = "Type";

// Status
export const PENDING = "pending";
export const APPROVED = "approved";
export const REJECTED = "rejected";

export const RULES = "RULES";

export const VERSION = "3.0";
