import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Add_Cash_Transaction_List_POST, All_Transaction_List_POST, Wallet_Withdraw_Request_List_POST } from "../../api/ApiConst";
import { callPostApi } from "../../api/ApiCaller";
import { NO_ERROR } from "../../api/constants";
import DateUtilities from "../../utils/DateUtilities";
import * as staticConstants from "../../constants/staticConstants";
import Loader from "../common/Loader";
import { convertAmount } from "../../utils/CurrencyUtils";
import Wrapper from "components/Wrapper";
import { useNavigate } from "react-router-dom";

const History = () => {
    const [transactionHistoryList, setTransactionHistoryList] = useState(null);
    const [historyListDetails, setHistoryListDetails] = useState(null);
    const [currentAllPage, setCurrentAllPage] = useState(0);
    const [currentDepositPage, setCurrentDepositPage] = useState(0);
    const [currentWithdrawPage, setCurrentWithdrawPage] = useState(0);
    const [loading, setLoading] = useState(null);
    const [historyCardId, setHistoryCardId] = useState("");
    const [currentTab, setCurrentTab] = useState("All");
    const [type, setType] = useState(true);

    const navigate = useNavigate();
    useEffect(() => {
        setTransactionHistoryList(null);
        setCurrentAllPage(0);
        setCurrentWithdrawPage(0);
        setCurrentDepositPage(0);
        setHistoryListDetails(null);
        setLoading("preData");
        getList();
    }, []);

    const getList = () => {
        if (currentTab === "All") {
            const rObj = {
                pageNumber: currentAllPage,
                pageSize: 10,
                search: "",
            };
            if (!loading) {
                setLoading("moreData");
            }
            callPostApi(All_Transaction_List_POST, rObj, {}, (response) => {
                if (response.errorCode === NO_ERROR) {
                    if (currentAllPage === 0) {
                        setTransactionHistoryList(response.responsePacket.data);
                    } else {
                        const transactionHistoryListClone = [...transactionHistoryList, ...response.responsePacket.data];
                        setTransactionHistoryList(transactionHistoryListClone);
                    }
                    setCurrentAllPage(currentAllPage + 1);
                    setHistoryListDetails({
                        recordsFiltered: response.responsePacket.recordsFiltered,
                        recordsTotal: response.responsePacket.recordsTotal,
                        total: response.responsePacket.total,
                    });
                    setLoading(null);
                } else {
                    toast.error(response.message, { id: "transactionList" });
                    setLoading(null);
                }
            });
        }

        if (currentTab === "Deposit") {
            const rObj = {
                pageNumber: currentDepositPage,
                pageSize: 10,
                search: "",
            };
            if (!loading) {
                setLoading("moreData");
            }
            callPostApi(Add_Cash_Transaction_List_POST, rObj, {}, (response) => {
                if (response.errorCode === NO_ERROR) {
                    if (currentDepositPage === 0) {
                        setTransactionHistoryList(response.responsePacket.data);
                    } else {
                        const transactionHistoryListClone = [...transactionHistoryList, ...response.responsePacket.data];
                        setTransactionHistoryList(transactionHistoryListClone);
                    }
                    setCurrentDepositPage(currentDepositPage + 1);
                    setHistoryListDetails({
                        recordsFiltered: response.responsePacket.recordsFiltered,
                        recordsTotal: response.responsePacket.recordsTotal,
                        total: response.responsePacket.total,
                    });
                    setLoading(null);
                } else {
                    toast.error(response.message, { id: "transactionList" });
                    setLoading(null);
                }
            });
        }
        if (currentTab === "Withdraw") {
            const rObj = {
                pageNumber: currentWithdrawPage,
                pageSize: 10,
                search: "",
            };
            if (!loading) {
                setLoading("moreData");
            }
            callPostApi(Wallet_Withdraw_Request_List_POST, rObj, {}, (response) => {
                if (response.errorCode === NO_ERROR) {
                    if (currentWithdrawPage === 0) {
                        setTransactionHistoryList(response.responsePacket.data);
                    } else {
                        const transactionHistoryListClone = [...transactionHistoryList, ...response.responsePacket.data];
                        setTransactionHistoryList(transactionHistoryListClone);
                    }
                    setCurrentWithdrawPage(currentWithdrawPage + 1);
                    setHistoryListDetails({
                        recordsFiltered: response.responsePacket.recordsFiltered,
                        recordsTotal: response.responsePacket.recordsTotal,
                        total: response.responsePacket.total,
                    });
                    setLoading(null);
                } else {
                    toast.error(response.message, { id: "transactionList" });
                    setLoading(null);
                }
            });
        }
    };

    useEffect(() => {
        setCurrentAllPage(0);
        setCurrentWithdrawPage(0);
        setCurrentDepositPage(0);
        getList();
        if (currentTab === "All") {
            setType(true);
        } else {
            setType(false);
        }
    }, [currentTab]);

    const handleHistoryTransactionClick = (transactionId) => {
        if (historyCardId === transactionId) {
            setHistoryCardId("");
        } else {
            setHistoryCardId(transactionId);
        }
    };

    return (
        <>
            <Wrapper>
                <div className="customHeader">
                    <span className=" position-absolute" style={{ left: "10px" }}>
                        <img src="assets/img/backIcon.png" className="cursor-pointer" alt="back left arrow" style={{ width: "50px" }} onClick={() => navigate(-1)} />{" "}
                    </span>
                    <span className="headerTitle">Points History</span>
                </div>
                <div className="p-3">
                    {/* <div className="TransactionBtnBox">
                        <span className={`TransactionBtn ${currentTab === "All" && "active"}`} onClick={() => setCurrentTab("All")}>
                            All
                        </span>
                        <span className={`TransactionBtn  ${currentTab === "Deposit" && "active"}`} onClick={() => setCurrentTab("Deposit")}>
                            Deposit
                        </span>
                        <span className={`TransactionBtn  ${currentTab === "Withdraw" && "active"}`} onClick={() => setCurrentTab("Withdraw")}>
                            Withdrawals
                        </span>
                    </div> */}
                    <div className="TransactionContent">
                        {loading === "preData" ? (
                            <Loader />
                        ) : (transactionHistoryList && (transactionHistoryList.length > 0 ? (
                            transactionHistoryList.map((transaction, i) => (
                                <div key={i} className="yellowCard mb-1 rounded">
                                    {type && <div
                                        className={`innerCard  rounded px-2 py-2 history_list ${historyCardId === transaction.transactionId ? "clicked" : ""}`}
                                        onClick={() => handleHistoryTransactionClick(transaction.transactionId)}
                                    >
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <div className="text-white font-size-12">
                                                <span>{staticConstants.TXN} # </span>
                                                <span className="text-black ml-2">{transaction.transactionId}</span>
                                            </div>
                                            <div className="text-white font-size-12 Maison-font">
                                                <span className={`${transaction.drCr === "Cr" ? "text-danger" : "text-green"} ml-2`}>{convertAmount(transaction.transactionAmount)}</span>
                                            </div>
                                            <div className="text-white font-size-12 Maison-font">
                                                <span className=" ml-2">{convertAmount(transaction.closingBalance)}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="text-white font-size-12">
                                                <span className="text-black">{transaction?.transactionType}</span>
                                            </div>
                                            <div className="text-white font-size-12 Maison-font">
                                                <span className="text-black">
                                                    <i className="fa fa-calendar text-white mx-2" aria-hidden="true"></i>
                                                    {transaction?.createdAt && DateUtilities.formattedDate(transaction?.createdAt, "MMM dd, uuuu, hh:mm aa")}
                                                    {transaction?.createdAtTimeStamp && DateUtilities.formattedDate(transaction?.createdAtTimeStamp, "MMM dd, uuuu, hh:mm aa")}
                                                </span>
                                            </div>
                                        </div>
                                        {historyCardId === transaction.transactionId ? (
                                            <div className="text-white font-size-12 Maison-font mt-2">
                                                <span className="">Remark : {transaction.remark}</span>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>}
                                    {!type && <div
                                        className={`innerCard  rounded px-2 py-2 history_list ${historyCardId === transaction.transactionId ? "clicked" : ""}`}
                                        // onClick={() => handleHistoryTransactionClick(transaction.transactionId)}
                                    >
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <div className="text-white font-size-12">
                                                <span>{staticConstants.TXN} # </span>
                                                <span className="text-black ml-2">
                                                    {currentTab === "Deposit" ? transaction.paymentTransactionRefId : transaction.id}</span>
                                            </div>
                                            <div className="text-white font-size-12 Maison-font">
                                                <span className={`${transaction.drCr === "Cr" ? "text-danger" : "text-green"} ml-2`}>{convertAmount(transaction?.amount)}</span>
                                            </div>
                                            {/* <div className="text-white font-size-12 Maison-font">
                                                    <span className=" ml-2">{convertAmount(transaction.closingBalance)}</span>
                                                </div> */}
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="text-white font-size-12">
                                                <span className="text-black">{currentTab === "Deposit" ? transaction?.paymentStatus : transaction?.withdrawTransactionStatus}</span>
                                            </div>
                                            <div className="text-white font-size-12 Maison-font">
                                                <span className="text-black">
                                                    <i className="fa fa-calendar text-white mx-2" aria-hidden="true"></i>
                                                    {transaction?.createdAt && DateUtilities.formattedDate(transaction?.createdAt, "MMM dd, uuuu, hh:mm aa")}
                                                    {transaction?.createdAtTimeStamp && DateUtilities.formattedDate(transaction?.createdAtTimeStamp, "MMM dd, uuuu, hh:mm aa")}
                                                </span>
                                            </div>
                                        </div>
                                        {historyCardId === transaction.transactionId ? (
                                            <div className="text-white font-size-12 Maison-font mt-2">
                                                <span className="">Remark : {transaction.remark}</span>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>}
                                </div>
                            ))
                        ) : (
                            <div className="my-3 text-white text-center">No Data Available!</div>
                        ))
                        )}
                        {loading === "moreData" ? (
                            <Loader />
                        ) : (
                            historyListDetails &&
                            historyListDetails.recordsTotal > transactionHistoryList.length && (
                                <div className="py-2 text-center">
                                    <button className="btn custom-yellow-btn text-black font-size-14" onClick={getList}>
                                        Load More
                                    </button>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default History;
